import type { ThemeDefinition } from 'vuetify'
import { createVuetify } from 'vuetify'

// import {aliases, mdi} from "vuetify/lib/iconsets/mdi";
import '@mdi/font/css/materialdesignicons.css'

// import fr from "vuetify/locale/fr";
import * as labsComponents from 'vuetify/labs/components'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const pildomTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#1a536aff',
        dark: '#363636',
        secondary: '#1a67acff',
        danger: '#B00020',
        error: '#B00020',
        info: '#1A536A',
        success: '#468355',
        warning: '#edb846ff',
    },
}

export default defineNuxtPlugin((nuxt) => {
    const vuetify = createVuetify({
        theme: {
            defaultTheme: 'pildomTheme',
            themes: {
                pildomTheme,
            },
        },
        ssr: true,
        // components,
        components: {
            ...components,
            ...labsComponents,
        },
        directives,
        icons: {
            defaultSet: 'mdi',
        },

    })
    nuxt.vueApp.use(vuetify)
})
